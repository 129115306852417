import React from "react";
import Header from "../../components/generic/header";
import { Link } from "react-router-dom";

export default function LegalsCGV() {
    return (
        <>
            <Header type={'header-min'}/>
            <main>
                <section className="page-padding">
                    <div>
                        <h2>Conditions générales de vente (CGV)</h2>
                    </div>
                    <div>
                        <p className="mb-1">Date de dernière mise à jour : 28/03/2025</p>
                    </div>
                </section>
                <section className="page-padding">
                    <h3>Article 1 : Objet</h3>
                    <p>Les présentes Conditions Générales de Vente (CGV) régissent les relations commerciales entre SEVENTEEN INFORMATIQUE (ci-après dénommé "le Prestataire"), micro-entreprise immatriculée sous le numéro SIRET 97760431300014, et ses clients (ci-après dénommés "le Client"). Elles s'appliquent à toutes les prestations de services fournies par le Prestataire.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 2 : Définitions</h3>
                    <p>Pour l’interprétation et la mise en œuvre des présentes Conditions Générales, il est convenu que les termes ci-après seront définis de la façon suivante :</p>
                    <ul>
                        <li><strong>Prestation</strong> : Désigne l’objet de la vente effectuée par le Prestataire. La prestation peut inclure un ensemble de services tels que le conseil, la mise à disposition d’un savoir-faire, la concession de licence d’exploitation de logiciel, les prestations annexes à l’acquisition d’un logiciel et le suivi de ces derniers, une prestation intellectuelle, la conception d’un site web ou d’une application métier, la mise à disposition de ressources humaines, etc. La prestation peut également désigner la vente d’objets mobiliers tels qu’un appareil, un composant ou un support informatique.</li>
                        <li><strong>Site Web ou Site Internet</strong> : Désigne l’ensemble de pages composées de textes, d’images et le cas échéant d’éléments multimédias, accessible par une adresse URL et hébergée sur le disque dur d’un serveur permettant des accès multiples et simultanés via le réseau Internet.</li>
                        <li><strong>Sources</strong> : Désigne le contenu élémentaire nécessaire à la création d’une prestation immatérielle : textes, images et sons pour un site Internet, documents comptables pour une solution de comptabilité, etc. Sauf mention contraire, la fourniture des sources est à la charge du Client.</li>
                        <li><strong>Cahier des Charges</strong> : Désigne un document fourni par le Prestataire, ou réalisé par le Prestataire en collaboration avec le Client contre rémunération, décrivant le contenu de la prestation que propose de réaliser le Prestataire et des éventuelles contraintes et spécificités concernant les conditions techniques de production, d’exploitation et de qualité d’une prestation. Le Cahier des Charges peut aussi porter le titre de « Devis », « Proposition » ou « Bon de Commande ». Le Cahier des Charges ne constitue un élément contractuel qu’à partir de l’instant où il a été validé par les deux parties.</li>
                        <li><strong>Prestation Forfaitaire</strong> : Désigne toute prestation dont le prix rémunère le Prestataire indépendamment des quantités de temps (heures, jours) mises en œuvre pour réaliser la prestation décrite dans le Cahier des Charges. À contrario, une prestation dont le montant est fixé par un nombre d’unités de temps (heures, jours) n’est pas considérée comme étant une prestation forfaitaire.</li>
                        <li><strong>Modification</strong> : Désigne toute demande de changement ou d'ajustement apportée par le Client sur le contenu ou la structure du site internet, y compris les corrections orthographiques, les changements de texte, d'images, de mise en page, ou toute autre altération demandée après la validation initiale du projet.</li>
                    </ul>
                </section>
                <section className="page-padding">
                    <h3>Article 3 : Application et Opposabilité des CGV</h3>
                    <p>Les présentes CGV sont systématiquement adressées par un lien internet ou remises à chaque Client en même temps que le Cahier des Charges et sont accessibles à tout moment sur le site internet du Prestataire à l'adresse <Link to={'/legals/cgv'}>https://seventeeninfo.fr/legals/cgv</Link>.</p>
                    <p>En conséquence, le fait de passer commande implique l’adhésion entière et sans réserve du Client à ces CGV, à l’exclusion de tout autre. Aucune condition particulière ne peut, sauf acceptation formelle et écrite du Prestataire au Client, prévaloir sur les CGV. Toute condition contraire opposée par le Client sera, donc, à défaut d’acceptation expresse, inopposable au Prestataire, quel que soit le moment où elle aura pu être portée à sa connaissance.</p>
                    <p>Le fait pour une personne physique ou morale, de commander un service ou produit du Prestataire emporte acceptation pleine et entière des présentes CGV.</p>
                    <p>Le Prestataire peut modifier, réactualiser ou rectifier les présentes, notamment afin de prendre en compte une évolution législative, réglementaire, jurisprudentielle et/ou technique. Le Prestataire prévient, dans la mesure du possible, ses Clients de toute modification de ses CGV sur la page de présentation des CGV de son site Internet. L’actualisation ne concerne en aucun cas les prestations déjà exécutées ou en cours d’exécution, sauf pour celles dont la durée restante est supérieure à six mois à la date de la modification. Dans ce cas, les dernières CGV sont mises en application entre les parties.</p>
                    <p>Dans le cas où l’une des dispositions des présentes est réputée ou déclarée nulle, ou non écrite, par un tribunal compétent, les autres dispositions restent intégralement en vigueur et doivent être interprétées de façon à respecter l’intention originelle des parties exprimée dans ce document.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 4 : Description des Services</h3>
                    <p>Le Prestataire propose des services de création de site vitrine WordPress, incluant :</p>
                    <ul>
                        <li>L'installation et la configuration de WordPress.</li>
                        <li>La création de pages personnalisées (maximum 5 pages).</li>
                        <li>La personnalisation du thème.</li>
                        <li>L'intégration de contenu fourni par le Client.</li>
                        <li>Les tests et ajustements nécessaires.</li>
                    </ul>
                </section>
                <section className="page-padding">
                    <h3>Article 5 : Devis et Acceptation</h3>
                    <p>Toute demande de prestation fait l'objet d'un devis gratuit établi par le Prestataire. Le devis est valable pour une durée d'un mois à compter de sa date d'émission. L'acceptation du devis par le Client implique l'acceptation sans réserve des présentes CGV.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 6 : Prix et Consistance de la Prestation</h3>
                    <p>Tous les prix s’entendent hors taxes. Les prix et les délais sont valables un mois à compter de la date d’émission du devis. Ils sont fermes et non révisables à la commande si celle-ci intervient dans le courant de ce mois.</p>
                    <p>La prestation comprend tout ce qui est explicitement listé dans le devis. Toute prestation ne figurant pas dans la proposition fera l’objet d’un devis complémentaire gratuit.</p>
                    <p>Les corrections demandées par le Client ne sont pas illimitées. Par défaut, sont incluses 3 modifications sur la page d'accueil et 1 modification pour chaque autre page. Toute correction supplémentaire fera l’objet de facturation à l’heure sur la base de 60€ HT de l’heure, sauf disposition contraire en annexes ou dans le devis et/ou facture. Une modification inclut toute demande de changement ou d'ajustement apportée par le Client sur le contenu ou la structure du site internet, y compris les corrections orthographiques, les changements de texte, d'images, de mise en page, ou toute autre altération demandée après la validation initiale du projet.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 7 : Modalités de Paiement</h3>
                    <p>Le paiement des prestations s'effectue selon les modalités suivantes :</p>
                    <ul>
                        <li>Un acompte de 30% à la signature du devis.</li>
                        <li>Le solde à la livraison finale du projet.</li>
                    </ul>
                    <p>Les paiements peuvent être effectués par virement bancaire, chèque, ou carte bancaire. Tout retard de paiement entraînera des pénalités calculées sur la base de 10% du montant TTC du prix des prestations.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 8 : Termes de Paiement</h3>
                    <p>Le délai de paiement est de 30 jours à réception de la facture, les acomptes étant payables comptant. Tout retard de paiement à compter du 31ème jour par rapport à l’émission de la facture donnera lieu à des pénalités de retard calculées suivant les règles légales en vigueur. Le taux des pénalités de retard de paiement est égal au taux d’intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente, majoré de 5 points (art. L.441-6 al. 3 du Code de commerce).</p>
                </section>
                <section className="page-padding">
                    <h3>Article 9 : Délai de Réalisation</h3>
                    <p>Le Prestataire s'engage à respecter les délais de réalisation indiqués dans le devis, sous réserve de la réception de toutes les informations et éléments nécessaires à la réalisation des prestations. Tout retard dans la transmission de ces éléments par le Client peut entraîner un report du délai de livraison.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 10 : Obligations du Client</h3>
                    <p>Le Client s'engage à fournir au Prestataire toutes les informations et documents nécessaires à la réalisation des prestations. Le Client est responsable de la véracité et de l'exactitude des informations fournies.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 11 : Droit de Publicité</h3>
                    <p>Sauf mention contraire explicite du Client, notifiée par courrier avec accusé de réception, le Prestataire se réserve le droit de mentionner sa réalisation pour le Client comme référence dans le cadre de ses démarches de prospection commerciale, de communication externe et de publicité.</p>
                    <p>Le Client autorise le Prestataire, à travers ses représentants légaux et commerciaux, à utiliser, à des fins purement démonstratives, la réalisation. Cette autorisation s’étend plus particulièrement aux éléments constitutifs de la réalisation, comprenant sans restriction la présentation publique des contenus suivants : les contenus textuels, les contenus iconographiques ou les deux.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 12 : Propriété Intellectuelle</h3>
                    <p>Le Prestataire conserve l'ensemble des droits de propriété intellectuelle sur les créations réalisées pour le Client jusqu'au paiement intégral des prestations. Une fois le paiement effectué, les droits sont transférés au Client, sous réserve des droits des tiers.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 13 : Confidentialité</h3>
                    <p>Le Prestataire s'engage à respecter la confidentialité des informations transmises par le Client dans le cadre de la réalisation des prestations.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 14 : Responsabilité</h3>
                    <p>Le Prestataire s'engage à exécuter les prestations conformément aux règles de l'art et aux standards professionnels. Toutefois, la responsabilité du Prestataire ne saurait être engagée en cas de force majeure ou de fait imputable au Client. Le Prestataire ne peut être tenu responsable de l'appréciation ou de l'utilisation du contenu par les utilisateurs du site.</p>
                    <p>Le Prestataire n'est pas responsable des plugins WordPress, de leurs légalités, vulnérabilités, ou de tout autre aspect lié à leur utilisation. Le Client est seul responsable de la sélection, de l'installation, et de la mise à jour des plugins utilisés sur son site.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 15 : Annulation</h3>
                    <p>En cas d'annulation de la commande par le Client après le début du projet, le Client sera redevable d'une partie de la somme correspondant aux travaux déjà réalisés. Le montant exact sera déterminé en fonction de l'avancement du projet au moment de l'annulation.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 16 : Maintenance</h3>
                    <p>La maintenance du site internet n'est pas incluse dans les prestations de création. Toute demande de maintenance fera l'objet d'une tarification supplémentaire, sur la base d'un devis établi par le Prestataire.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 17 : Contenu du Site Internet</h3>
                    <p>Le Prestataire ne peut être tenu responsable ni poursuivi pour le contenu du site internet. Le contenu doit être rédigé par le Client et fourni au Prestataire pour intégration. Le Client est seul responsable de la publication du contenu sur son site. Le Prestataire décline toute responsabilité quant à la nature, la légalité, ou l'exactitude du contenu publié par le Client.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 18 : Hébergement du Site Internet</h3>
                    <p>Sur demande du Client et moyennant une facturation supplémentaire, le Prestataire peut mettre en ligne le site internet sur des serveurs. Ces serveurs sont gérés par une entreprise externe choisie par le Client et ne relèvent en aucun cas de la responsabilité du Prestataire SEVENTEEN INFORMATIQUE. En cas d'indisponibilité du site internet liée à l'hébergement, le Prestataire ne peut être tenu responsable.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 19 : Mentions Commerciales</h3>
                    <p>Sauf mention contraire explicite du Client, le Prestataire se réserve la possibilité d’inclure dans la réalisation une mention commerciale indiquant clairement sa contribution, sous la forme d’une mention du type : “Réalisation : SEVENTEEN INFORMATIQUE” ou “Conception : SEVENTEEN INFORMATIQUE”.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 20 : Responsabilité et Propriété des Contenus</h3>
                    <p>Le Client reconnaît et assume la pleine et entière responsabilité des choix réalisés en matière de contenus textuels et iconographiques figurant dans la réalisation livrée par le Prestataire. La responsabilité du Prestataire ne saurait être engagée dès lors que le "Bon à Tirer" aura été validé par le Client, par mail ou sur papier.</p>
                    <p>Le Client reconnaît la pleine et entière responsabilité de ses choix dans tous les contenus qu’il présente, fournit ou demande au Prestataire pour la réalisation de la prestation. La responsabilité du Prestataire ne pourra en aucun cas être recherchée dans l’hypothèse où ces contenus ne se révéleraient pas libres de droits ou seraient attentatoires aux droits de propriétés intellectuelles d’un tiers. Le Client garantit le Prestataire à cet égard de prendre à sa charge toute condamnation, frais et honoraires mis à la charge ou supportés par le Prestataire pour se défendre du fait de la défaillance du Client.</p>
                    <p>Le Client reconnaît avoir pris connaissance des mises en garde effectuées par le Prestataire concernant les dispositions légales relatives à la propriété intellectuelle et les peines pouvant être encourues en cas de violation.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 21 : Force Majeure</h3>
                    <p>Le Prestataire ne pourra être tenu pour responsable d’un délai non respecté pour cause de tout cas fortuit ou de force majeure comme, en particulier, tout acte émanant d’une autorité civile ou militaire, de fait ou de droit de grève, incendie, inondation, dégâts des eaux, tempête et foudre, accident, émeute, attentat, de non livraison des documents pour la création ou la mise en service du produit, tout fait imputable à un tiers, ou autre circonstance ayant une cause externe et l’empêchant, directement ou au travers d’un tiers, de répondre aux dites obligations.</p>
                </section>
                <section className="page-padding">
                    <h3>Article 22 : Litiges</h3>
                    <p>En cas de litige relatif à l'interprétation ou à l'exécution des présentes CGV, les parties s'efforceront de trouver une solution amiable. À défaut, le litige sera soumis aux tribunaux compétents.</p>
                </section>
            </main>
        </>
    );
}
